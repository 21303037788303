<script setup>

</script>

<template>
  <div id="registration_result">
    {{ registerResult }}
  </div>
  <div class="register-form">
    <h2>Register</h2>
    <div class="form-group">
      <label for="email">
        Email:
        <span class="invalid" v-if="emailValid === false">Invalid Email Address</span>
        <input type="email" v-model="username">
      </label>
    </div>
    <div class="form-group">
      <label for="password">
        Password:
        <span class="invalid" v-if="passwordsMatch === false">Passwords do not match</span>
        <input type="password" v-model="password">
      </label>
    </div>
    <div class="form-group">
      <label for="password">
        Confirm Password:
        <span class="invalid" v-if="passwordsMatch === false">Passwords do not match</span>
        <input type="password" v-model="confirmPassword">
      </label>
    </div>
    <button class="submit" @click="register">Sign-Up</button>
  </div>
</template>

<script>
import store from "@/store";
import axios from "axios";

export default {
  name: 'RegisterForm',
  components: {

  },
  computed: {
    count () {
      return store.state.count
    }
  },
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      registerResult: '',
      emailValid: true,
      passwordsMatch: true
    }
  },
  methods: {
    register() {
      this.$store.state.loading = true
      this.emailValid = true
      this.passwordsMatch = true

      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.username) === false) {
        this.emailValid = false
        this.$store.state.loading = false
      }

      if(this.password !== this.confirmPassword) {
        this.passwordsMatch = false
        this.$store.state.loading = false
      }

      if(this.emailValid === false || this.passwordsMatch === false) {
        return
      }

      axios.post('https://api.guiltfreebiblereading.com/register', {
        username: this.username,
        password: this.password,
      })
          .then(response => {
            if(!response.data.success) {
              alert(response.data.message)
              store.state.authenticated = false
            }
            alert(response.data.message)
          })

      this.$store.state.loading = false

    },
  }
}
</script>

<style scoped lang="scss">
  .register-form{
    background-color: #F1F1F1;
    margin: 1rem auto;
    padding: 2rem;
    .form-group{
      padding: 1rem 0;
      label{
        font-weight: bold;
        text-align: left;
        display: block;
      }
    }
    h2{
      margin: 0;
      color: #2c3e50;
    }
    input[type=email], input[type=password]{
      display: block;
      width: 100%;
      padding: 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid #2c3e50;
      background-color: #FFFFFF;
      font-weight: bold;
    }
    button.submit{
      background-color: #42b983;
      color: #2c3e50;
      font-size: 1.1rem;
      font-weight: bold;
      border: none;
      padding: 0.75rem 2rem;
      border-radius: 0.5rem;
    }
  }
  .invalid{
    color: #C00;
    font-weight: bold;
  }
</style>
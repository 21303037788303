import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      count: 0,
      token: '',
      email: '',
      authenticated: false,
      bible: [],
      loading: false,
    }
  },
  getters: {
  },
  mutations: {
    increment (state) {
      state.count++
    },
    updateToken (state, token){
      state.token = token
    },
    setAuthenticated (state, auth){
      state.authenticated = auth
    },
    setBible (state, bible) {
      state.bible = bible
    }
  },
  actions: {
  },
  modules: {
  }
})

<template>
  <div>
    <h1>Guilt Free Bible Reading</h1>
    <div class="highlights">
      <p>
        No ads. No unnecessary personal information. No guilt.
      </p>
      <p>
        Sign up for a free account and track your Bible reading.
        No pressure to finish in any particular time frame. No hounding. Your progress is stored for your convenience,
        not your shame.
      </p>
    </div>

    <RegisterForm />
  </div>


</template>

<script>
// @ is an alias to /src
import RegisterForm from "@/components/RegisterForm.vue";
import store from "@/store";
import router from "@/router";
export default {
  name: 'HomeView',
  components: {
    RegisterForm
  },
  mounted() {
    if(store.state.authenticated) {
      router.push('/bible')
    }
  },
  computed: {
    authenticated() {
      return store.state.authenticated
    }
  },
  methods: {

  }
}
</script>
<style scoped>
  .highlights{
    color: #2c3e50;
    font-size: 2rem;
    padding: 1.5rem;
    border: 1px solid #42b983;
  }
</style>